<script setup lang="ts">
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import type { searchNode, SuggestedSearchesType } from '~/types/search.ts';
import { getSearchQueryPath } from '~/utils/helpers.ts';

interface Props {
	searchesData: SuggestedSearchesType;
}

defineProps<Props>();

const { $eventClient } = useNuxtApp();
const route = useRoute();
const pathName = computed(() => route.path);

const searchQuery: Ref<string> = inject('debouncedSearchQuery')!;
const filters: Ref<string> = inject('filters')!;
const filterValue: Ref<string> = inject('filterValue')!;
const handleFocusOut: () => void = inject('handleFocusOut')!;
const nodeFromQuery: Ref<searchNode> = inject('nodeFromQuery')!;
const selectedNode: Ref<searchNode> = inject('selectedNode')!;

const handleSearchQuery = async (query: string) => {
	$eventClient.sendEvent('search-suggestion-click', {
		searchQuery: searchQuery.value,
		type: 'search others',
		href: query,
	});

	const searchPath = getSearchQueryPath(
		filters.value,
		nodeFromQuery.value!.id,
		selectedNode.value.id,
		query,
		pathName.value,
	);

	filterValue.value = query;
	handleFocusOut();
	await navigateTo(searchPath);
};
</script>

<template>
	<div class="flex flex-col lg:px-3xl">
		<h1 class="py-xl text-gray-500 text-sm font-semibold">Others</h1>
		<div class="flex flex-col gap-xs">
			<div
				v-for="(query, idx) in searchesData"
				:key="idx"
				class="lg:px-lg py-md flex items-center gap-xl hover:bg-gray-50 cursor-pointer rounded-xs"
				@click="() => handleSearchQuery(query)"
			>
				<PhMagnifyingGlass size="20" class="text-gray-400" />
				<span class="text-xs">
					{{ query }}
				</span>
			</div>
		</div>
	</div>
</template>
