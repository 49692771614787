import { type Static, Type } from '@sinclair/typebox';

export interface searchNode {
	name: string;
	id: number | null;
}

const SuggestedBrands = Type.Array(
	Type.Object({
		name: Type.String(),
		cover_image: Type.String(),
		href: Type.String(),
	}),
);

export type SuggestedBrandsType = Static<typeof SuggestedBrands>;

const SuggestedCategories = Type.Array(
	Type.Object({
		suggestion: Type.String(),
		href: Type.String(),
	}),
);

export type SuggestedCategoriesType = Static<typeof SuggestedCategories>;

const SuggestedSearches = Type.Array(Type.String());

export type SuggestedSearchesType = Static<typeof SuggestedSearches>;

export const SearchSuggestionsSchema = Type.Object({
	brands: SuggestedBrands,
	categories: SuggestedCategories,
	others: SuggestedSearches,
});

export type SearchSuggestionsResponse = Static<typeof SearchSuggestionsSchema>;
