<script setup lang="ts">
import { computeSubstring } from '~/utils/helpers.ts';
import type { SuggestedBrandsType } from '~/types/search.ts';

const LazyImage = defineAsyncComponent(
	() => import('~/components/LazyImage.vue'),
);

interface Props {
	brandsData: SuggestedBrandsType;
}

defineProps<Props>();

const { $eventClient } = useNuxtApp();

const searchQuery: Ref<string> = inject('debouncedSearchQuery')!;
const handleFocusOut: () => void = inject('handleFocusOut')!;

const handleBrandClick = (href: string) => {
	$eventClient.sendEvent('search-suggestion-click', {
		searchQuery: searchQuery.value,
		type: 'brand',
		href: href,
	});

	handleFocusOut();
};
</script>

<template>
	<div class="flex flex-col lg:px-3xl">
		<h1 class="py-xl text-gray-500 text-sm font-semibold">
			Top Selling Brands
		</h1>
		<div
			class="py-md flex flex-row w-full gap-7xl no-scrollbar overflow-x-auto whitespace-nowrap"
		>
			<div
				v-for="(brand, idx) in brandsData"
				:key="idx"
				class="gap-xs shrink-0"
			>
				<NuxtLink
					:to="`/${brand.href}`"
					class="flex flex-col items-center justify-center"
					@click="() => handleBrandClick(brand.href)"
				>
					<LazyImage
						:src="
							!!brand.cover_image
								? brand.cover_image
								: 'https://cdn.shopify.com/s/files/1/2337/7003/files/78e25c532e7bb6f9bb7ebe29fdbe7ea3.jpg?v=1725975421'
						"
						class="w-[74px] h-[74px] rounded-sm"
						alt="brand image"
					/>
					<div class="max-w-[100px] text-ellipsis truncate">
						<span class="font-semibold text-xs">
							{{ computeSubstring(brand.name, searchQuery).strLeft }}
						</span>
						<span class="font-normal text-xs">
							{{ computeSubstring(brand.name, searchQuery).strRight }}
						</span>
					</div>
				</NuxtLink>
			</div>
		</div>
	</div>
</template>
