<script setup lang="ts">
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computeSubstring } from '~/utils/helpers.ts';
import type { SuggestedCategoriesType } from '~/types/search.ts';

interface Props {
	categoriesData: SuggestedCategoriesType;
}

defineProps<Props>();

const { $eventClient } = useNuxtApp();

const searchQuery: Ref<string> = inject('debouncedSearchQuery')!;
const handleFocusOut: () => void = inject('handleFocusOut')!;

const handleBrandClick = (href: string) => {
	$eventClient.sendEvent('search-suggestion-click', {
		searchQuery: searchQuery.value,
		type: 'category',
		href: href,
	});

	handleFocusOut();
};
</script>

<template>
	<div class="flex flex-col lg:px-3xl">
		<h1 class="py-xl text-gray-500 text-sm font-semibold">
			Top Selling Categories
		</h1>
		<div class="flex flex-col gap-xs">
			<div
				v-for="(category, idx) in categoriesData"
				:key="idx"
				class="lg:px-sm py-md hover:bg-gray-50 cursor-pointer rounded-xs select-none"
			>
				<NuxtLink
					:to="`/${category.href}`"
					class="flex items-center gap-xl"
					@click="() => handleBrandClick(category.href)"
				>
					<PhMagnifyingGlass size="20" class="text-gray-400" />
					<div>
						<span class="font-semibold text-xs">
							{{ computeSubstring(category.suggestion, searchQuery).strLeft }}
						</span>
						<span class="font-normal text-xs">
							{{ computeSubstring(category.suggestion, searchQuery).strRight }}
						</span>
					</div>
				</NuxtLink>
			</div>
		</div>
	</div>
</template>
